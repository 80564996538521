.footer {
    width: 100%;
    float: left;
    background: $color_main;
    &__info {
        width: 100%;
        float: left;
        padding: 40px 0;
        .page-ducat {
            margin: 0 0 45px 0;
        }
    }
    &__top {
        width: 100%;
        float: left;
        padding: 60px 0;
        border-top: 1px solid #404040;
        &-title {
            font-size: 26px;
            font-family: $font_playfair;
            color: #fff;
            font-weight: 400;
            margin-bottom: 50px;
            @include media-breakpoint-down(sm) {
                margin: 30px 0 15px 0;
            }
        }
        &-desc {
            font-family: $font_opensans;
            font-size: 14px;
            color: #fff;
            font-weight: 300;
        }
        &-input {
            width: 100%;
            position: relative;
            @include media-breakpoint-down(sm) {
                margin-top: 15px;
            }
            input {
                background: none;
                width: 100%;
                max-width: 255px;
                border: 1px #fff;
                border-style: none none solid none;
                outline: none;
                color: #fff;
                float: right;
            }
        }
        &-more {
            font-family: $font_opensans;
            font-size: 14px;
            color: $color_second;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 2px;
            margin-top: 20px;
            display: block;
            &:hover {
                color: lighten($color_second, 10%);
                text-decoration: none;
            }
            &:before {
                content: '';
                width: 60px;
                height: 1px;
                background: $color_second;
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
            }
        }
    }
    &__content {
        width: 100%;
        float: left;
        padding: 35px 0;
        border-top: 1px solid #404040;
    }
    &__logo {
        float: left;
        margin-right: 25px;
    }
    &__copy {
        color: #999999;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $font_opensans;
        font-weight: 300;
        float: right;
        @include media-breakpoint-down(md) {
            margin-top: 15px;
        }
        span {
            float: left;
            &.copy {
                margin-left: 10px;
                &:before {
                    content: '';
                    width: 35px;
                    height: 1px;
                    background: #404040;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 13px;
                }
            }
        }
    }
    &__menu {
        float: left;
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }
        li {
            float: left;
            a {
                float: left;
                color: #999999;
                padding: 10px 25px;
                text-transform: uppercase;
                font-size: 12px;
                font-family: $font_opensans;
                font-weight: 300;
                @include media-breakpoint-down(md) {
                    padding: 10px 15px;
                }
            }
        }
    }
    &__contact {
        float: right;
        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: right;
        }
        span {
            color: #999999;
            font-size: 12px;
            font-family: $font_opensans;
            font-weight: 300;
            display: block;
            margin: 5px 0;
            a {
                color: #999999;
                text-decoration: underline;
            }
        }
    }
    .alert-success {
        background: #fff;
        color: #000;
        border: none;
    }
}
.fucking-eu-cookies.fucking-priority {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.8);
    font-weight: 300;
    padding: 6px 10px;
    z-index: 100000;
    span {
        font-size: 16px;
        color: #fff;
        padding-top: 3px;
        font-family: 'open sans', sans-serif;
    }
    button {
        margin-left: 35px;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        border: 1px solid #c89135;
        background: #000;
        color: #fff;
        font-weight: 300;
        padding: 15px 45px;
        font-family: 'open sans', sans-serif;
        &:hover {
            background: #c89135;
        }
    }
}
