@mixin phone-only {
    @media (max-width: 729px) {
        @content;
    }
}
@mixin tablet-down {
    @media (max-width: 949px) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: 730px) {
        @content;
    }
}
@mixin tablet-landscape-down {
    @media (max-width: 1149px) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: 950px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1150px) {
        @content;
    }
}
