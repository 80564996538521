.button {
  display:inline-block;
  background: none;
  border: 1px solid $color_second;
  padding:15px 25px;
  font-family:$font_opensans;
  font-weight:300;
  font-size:12px;
  color: $color_main;
  text-transform:uppercase;
  position:relative;
  transition: all ease 0.3s;
  outline:none;
  cursor:pointer;
  line-height: 1.5;
  text-align: center;
  @include media-breakpoint-down(lg) {
    padding:10px 40px;
  }
  @include media-breakpoint-down(md) {
    padding:10px 20px;
  }
  &:hover {
    background:$color_second;
    color: #fff;
    border: 1px solid $color_second;
    text-decoration:none;
  }
  &--centered {
    margin:0 auto;
    display:table;
  }
  &--dark {
    background:$color_second;
    color: #fff;
    border: 1px solid $color_second;
    &:hover {
      background: lighten($color_second,10%);
      color: #fff;
      border: 1px solid $color_second;
      text-decoration:none;
    }
  }
  &--white {
    color:#fff;
    &-border {
      border-color: #fff;
    }
  }
  &--black {
    background:#000;
    border: 1px solid #000;
    color:#fff;
    &-border {
      background: lighten(#000,10%);
      color:#fff;
      border-color: #000;
    }
  }
  &--gold {
    text-align: right;
    display: inline-block; 
    width: 180px; 
    height: 50px; 
    background: $color_second; 
    border: 1px solid transparent; 
    padding: 0 30px; 
    transition: background 200ms ease-in-out, border 200ms ease-in-out;
    span {
      color: #fff; 
      font-size: 14px; 
      text-transform: uppercase; 
      letter-spacing: 0.2em; 
      font-weight: 700; 
      line-height: 50px; 
      transition: color 200ms ease-in-out;
      &:before {
        content: ""; 
        height: 1px; 
        width: 26px; 
        background: #fff; 
        position: absolute; 
        top: 50%; 
        left: -40px; 
        transition: background 200ms ease-in-out;
          }
        }
        &:hover {
          background: #fff; 
          border: 1px solid $color_second;
          span {
            color: $color_second;
            &:before {
              background: $color_second;
            }
        }
      }
  }
  &--big {
    padding:15px 40px;
    font-size:18px;
    font-weight:600;
    letter-spacing:2px;
    @include media-breakpoint-down(md) {
      padding:15px 20px;
    }
  }
}