.banner {
    width: 100%;
    position: relative;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    &__socials {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-bottom: 20%;

        &--subpage {
            align-items: center;
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .socials {
            float: left;
            a {
                width: 26px;
                height: 26px;
                display: block;
                margin: 15px 0;
                &.social-ico {
                    background-image: url('../img/sprite-icon.png');
                    width: 26px;
                    height: 26px;
                    display: block;

                    &.fb-ico {
                        background-position: 0 0;

                        &:hover {
                            background-position: -27px 0;
                        }
                    }

                    &.ig-ico {
                        background-position: 0 -27px;

                        &:hover {
                            background-position: -27px -27px;
                        }
                    }
                }
            }
        }
        &--footer {
            padding-bottom: 2rem;
            .socials {
                float: none;
                display: flex;
                a {
                    margin: 15px 0.5rem;
                }
            }
        }
    }
}

.slider {
    width: 100%;
    float: left;
    position: relative;
    .bx-wrapper {
        border: 0;
        box-shadow: none;
        margin-bottom: 0;
        position: relative;
        z-index: 99;
        .bx-controls-direction {
            a {
                width: 17px;
                height: 15px;
                margin-top: -7px;
                @include media-breakpoint-down(sm) {
                    top: 25%;
                }
                @include media-breakpoint-down(xs) {
                    top: 20%;
                }
                &.bx-prev {
                    background: url('../images/slider_prev.png') left center no-repeat;
                    &:hover {
                        background-position: right center;
                    }
                }
                &.bx-next {
                    background: url('../images/slider_next.png') left center no-repeat;
                    &:hover {
                        background-position: right center;
                    }
                }
            }
        }
        .bx-pager {
            position: absolute;
            right: 2rem;
            bottom: -4rem;
            width: auto;
            padding: 0;
            display: flex;
            height: 3rem;
            flex-wrap: wrap;
            align-content: center;
            .bx-pager-item {
                font-size: 14px;
                line-height: 1;
                //width: 100%;
                a {
                    background: none;
                    font-size: 14px;
                    font-family: $font_opensans;
                    font-weight: 300;
                    color: #b2b2b2;
                    text-indent: 0;
                    display: inline-block;
                    margin: 15px 0;
                    text-decoration: none;
                    width: 2rem;
                    height: auto;
                    /*
                    &:after {
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: #fff;
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 20px;
                    }
                    */
                    &:hover {
                        text-decoration: none;
                    }
                    &.active {
                        color: $color_second;
                        font-size: 24px;
                        &:after {
                            background: $color_second;
                        }
                    }
                }
            }
        }
    }
    &--page {
        .slider__content {
            height: 250px;
            -webkit-background-size: cover;
            background-size: cover;
            background-position: center center;
            @include media-breakpoint-down(sm) {
                height: 150px;
            }
        }
    }
    &__content {
        width: 100%;
        float: left;
        position: relative;
        z-index: 98;
        img {
            width: 100%;
        }
    }
    &__item {
        position: relative;
        a {
            width: 100%;
        }
    }
    &__coints {
        position: absolute;
        bottom: -10%;
        right: 4%;
        z-index: 999;
        width: 30%;
        img {
            max-width: 100%;
            &:first-of-type {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            display: none;
        }

        @include media-breakpoint-down(md) {
            left: 4%;
            right: auto;
            width: 40%;
        }
    }
    &__text {
        position: absolute;
        top: 0;
        left: 10%;
        z-index: 99;
        text-align: right;
        width: 300px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        justify-content: center;
        @media (max-width: 1320px) {
            //top:12%;
            left: 5%;
        }
        @include media-breakpoint-down(lg) {
            //top:10%;
            left: 8%;
            width: 250px;
        }
        &__subtitle {
            font-size: 16px;
            font-family: $font_playfair;
            color: $color_second;
            font-weight: 700;
            margin-bottom: 25px;
            @media (max-width: 1320px) {
                font-size: 14px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }
            @include media-breakpoint-down(md) {
                font-size: 12px;
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
            &:after {
                content: '';
                width: 80px;
                height: 3px;
                background: $color_second;
                margin: 25px 0 0 auto;
                display: block;
                @include media-breakpoint-down(lg) {
                    margin: 15px 0 0 auto;
                }
                @include media-breakpoint-down(sm) {
                    margin: 10px 0 0 auto;
                }
            }
        }
        &__title {
            font-size: 33px;
            font-family: $font_playfair;
            color: #fff;
            font-weight: 400;
            line-height: 1.1;
            margin-bottom: 40px;
            display: block;
            text-decoration: none;
            &:hover {
                text-decoration: none;
                color: $color_second;
            }
            @media (max-width: 1320px) {
                font-size: 30px;
                margin-bottom: 20px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 30px;
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(md) {
                font-size: 26px;
                margin-bottom: 15px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 22px;
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 26px;
                margin-bottom: 10px;
            }
        }
        &__desc {
            color: #fff;
            font-size: 16px;
            font-family: $font_opensans;
            font-weight: 300;
            margin-bottom: 30px;
            @include media-breakpoint-down(lg) {
                font-size: 14px;
                margin-bottom: 20px;
            }
            @include media-breakpoint-down(md) {
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
        &__button {
            float: right;
            text-transform: uppercase;
            position: relative;
            z-index: 999;
            @include media-breakpoint-down(lg) {
                padding: 10px 20px;
            }
            @include media-breakpoint-down(md) {
                padding: 5px 15px;
                font-size: 14px;
            }
        }
    }

    .slider__item {
        .slider__head {
            position: absolute;
            bottom: 0;
            max-height: 95%;
            width: auto;
            left: 41%;
            @include media-breakpoint-down(md) {
                left: 50px;
            }
            @include media-breakpoint-down(sm) {
                left: -130px;
            }
            @media screen and (max-width: 400px) {
                left: -170px;
            }
        }

        .slider__border {
            position: absolute;
            left: 39%;
            top: 50px;
            bottom: 50px;
            right: 60px;
            border: 2px solid #c99d3a;
            @include media-breakpoint-down(md) {
                left: 30px;
                right: 30px;
            }
            @media screen and (max-width: 400px) {
                right: -20px;
            }
        }

        @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: flex-end;
            overflow: hidden;

            .slider__text {
                right: 60px;
                left: auto;
                width: 230px;
                max-width: 230px;
            }
            img {
                width: 100%;
                max-width: unset;
                object-fit: cover;
                object-position: left;
            }
        }
        @include media-breakpoint-up(md) {
            img {
                width: 100%;
            }
        }

        @media screen and (max-width: 400px) {
            .slider__text {
                right: 20px;
            }
            img {
                position: relative;
                //left: 70px;
            }
        }
    }

    &__controls {
        clear: both;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1rem;
    }
    &__pager {
        > div {
            display: flex;
            align-items: center;
        }
        a {
            font-family: $font_opensans;
            font-weight: 300;
            color: #b2b2b2;
            text-indent: 0;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            width: 2rem;
            height: auto;

            &.active {
                color: $color_second;
                //font-size: 24px;
                &:after {
                    background: $color_second;
                }
            }
            &:hover {
                color: $color_second;
            }
        }
    }
    &__next,
    &__prev {
        width: 1.25rem;
        height: 1.25rem;
        a {
            display: block;
            height: 100%;
            background: url('../img/arrow.png') no-repeat;
            background-size: contain;
            transition: filter 0.2s;
            &:hover {
                filter: brightness(0);
            }
        }
    }
    &__next {
        a {
            transform: rotate(180deg);
        }
    }
}
