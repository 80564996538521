:root {
    --main: #000000;
    --second: #c99d3a;
    --containerDesktop: 1100px;
    --containerPadding: 1.5rem;
    --secondFont: 'built_titlingregular', 'sans-serif';
    --font_opensans: 'Open Sans', sans-serif;
    --font_playfair: 'Playfair Display', serif;
    @include tablet {
        --containerPadding: 2rem;
    }
    @include desktop {
        --containerPadding: 0px;
    }
}

html {
    width: 100%;
    overflow-x: hidden;
}
body {
    font-family: $font_opensans;
    overflow-x: hidden;
    h1 {
        font-family: $font_playfair;
    }
}
.collection-offer__link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #c99d3a;
    text-align: right;
    letter-spacing: 2px;
    display: block;
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin-top: 2rem;
    &:before {
        content: '';
        width: 40px;
        height: 1px;
        background: #c99d3a;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}
.img-fluid {
    max-width: 100%;
    height: auto;
}

.header {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-top: 1rem;
    @include phone-only {
        justify-content: center;
        text-align: center;
    }
    @include media-breakpoint-up(xl) {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media (min-width: 1921px) {
        max-width: 1920px;
        padding-left: 0;
        padding-right: 0;
    }
}
p,
i,
.story-item__position {
    font-size: 1.1375rem;
    font-weight: 300;
    b,
    strong {
        font-weight: 600;
    }
}
.intro {
    padding-top: 1rem;
    font-size: 1.5rem;
    h2 {
        font-family: $font_playfair;
        color: $color_second;
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
    a {
        color: black;
    }
    .buttons {
        margin-top: 2rem;
        margin-bottom: 3rem;
        text-align: center;
    }
}

.products {
    .product {
        &__text {
            h2 {
                font-family: $font_playfair;
                color: $color_second;
                margin-bottom: 1rem;
            }
            a {
                color: black;
            }
        }
        &__image {
            padding-bottom: 2rem;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    @media (min-width: 992px) {
        .product {
            display: flex;
            flex-wrap: wrap;
            &__text,
            &__image {
                flex: 0 0 50%;
            }
            &__image {
                & + div {
                    flex: 0 0 100%;
                }
            }
        }
    }
}
