.story {
    margin-top: 7rem;
    > h2 {
        font-family: $font_playfair;
        font-weight: 700;
        color: $color_second;
        text-align: center;
    }
    &-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
        max-width: 80%;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
    &-item {
        width: 100%;
        margin-bottom: 50px;
        &__top {
            width: 100%;
            display: flex;
            align-items: center;
        }
        &__category {
            width: 50%;
            text-align: left;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-family: $font_opensans;
            font-weight: 300;
            font-size: 12px;
            color: $color_main;
            @include media-breakpoint-down(lg) {
                font-size: 11px;
            }
        }
        &__maintitle {
            width: 50%;
            text-align: right;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-family: $font_opensans;
            font-weight: 600;
            font-size: 12px;
            color: $color_second;
        }
        &__content {
            width: 100%;
            float: left;
            border: 2px solid $color_second;
            padding: 35px 55px 35px 10px;
            position: relative;
            z-index: 99;
            @include media-breakpoint-down(md) {
                padding: 25px;
            }
            @include media-breakpoint-down(sm) {
                padding: 15px;
            }
            @include media-breakpoint-up(lg) {
                &--space {
                    padding-top: 165px;
                }
            }
        }
        &__title {
            font-family: $font_playfair;
            font-weight: 700;
            font-size: 25px;
            color: $color_second;
            margin-bottom: 30px;
            display: block;
            transition: color 0.3s;
            &:hover {
                color: darken($color_second, 10%);
                text-decoration: none;
            }
        }
        &__position {
            font-family: 'Playfair Display', serif;
            font-weight: 700;
            font-size: 17px;
            color: $color_second;
            margin-bottom: 20px;
            display: block;
        }
        &__desc {
            font-family: $font_opensans;
            font-weight: 300;
            font-size: 16px;
            color: $color_main;
            margin-bottom: 15px;
            strong {
                font-weight: 700;
            }
            a {
                color: #000;
            }
            p {
                margin: 0 0 35px 0;
                /*&:first-child {
            margin:0 0 85px 0;
          } */
            }
        }
        &__date {
            color: #bebebe;
            font-family: $font_playfair;
            font-weight: 700;
            font-size: 18px;
            writing-mode: vertical-lr;
            position: absolute;
            top: 0;
            right: -40px;
            height: 100%;
            text-align: center;
        }
        &__link {
            font-family: $font_opensans;
            font-weight: 300;
            font-size: 12px;
            color: #000;
            text-align: right;
            letter-spacing: 2px;
            display: block;
            text-transform: uppercase;
            &:before {
                content: '';
                width: 40px;
                height: 1px;
                background: $color_main;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
            }
            &:hover {
                color: $color_second;
                text-decoration: none;
                &:before {
                    background: $color_second;
                }
            }
        }
        &__img {
            position: relative;
            min-height: 200px;
            &--left,
            &--right {
                img {
                    z-index: 100;
                }
            }
            &--left {
                @include media-breakpoint-down(md) {
                    text-align: center;
                    margin-bottom: 10px;
                }
                img {
                    position: absolute;
                    top: -10px;
                    left: 0;
                    max-width: 390px;
                    @include media-breakpoint-down(lg) {
                        max-width: 330px;
                        top: 0;
                    }
                    @include media-breakpoint-down(md) {
                        position: relative;
                        top: 0;
                        left: 0;
                        max-width: 100%;
                    }
                }
            }
            &--right {
                @include media-breakpoint-down(md) {
                    text-align: center;
                    margin-bottom: 10px;
                }
                img {
                    position: absolute;
                    top: -10px;
                    right: 0;
                    max-width: 390px;
                    @include media-breakpoint-down(lg) {
                        max-width: 330px;
                        top: 0;
                    }
                    @include media-breakpoint-down(md) {
                        position: relative;
                        top: 0;
                        right: 0;
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }
        }
        &__button {
            margin-top: 200px;
            padding: 10px 25px;
            @include media-breakpoint-down(lg) {
                padding: 10px 10px;
            }
            @include media-breakpoint-down(md) {
                margin: 0 0 15px 0;
                padding: 10px 10px;
            }
        }
        &__quote {
            color: #000;
            font-family: $font_playfair;
            font-weight: 700;
            font-size: 26px;
            margin: 80px 0 15px 0;
            @include media-breakpoint-down(lg) {
                font-size: 22px;
            }
            @include media-breakpoint-down(md) {
                margin: 0 0 15px 0;
            }
        }
        &--left {
            .story-item__top {
                padding-left: 30px;
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }
            }
            .story-item__content {
                padding: 35px 55px 35px 35px;
            }
            .story-item__date {
                right: 100%;
                margin-right: 15px;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
            }
            @include media-breakpoint-up(lg) {
                .story-item__button {
                    float: right;
                    clear: both;
                }
                .story-item__quote {
                    float: right;
                    clear: both;
                    text-align: right;
                }
            }
        }
        &--garant {
            margin-top: 40px;
            .story-item__content {
                padding: 35px 20px;
            }
            .story-item__img--left {
                img {
                    top: -35px;
                }
            }
            .story-item__img--right {
                img {
                    top: -35px;
                }
            }
        }
    }
    &-about {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 70px auto 120px auto;
        justify-content: center;
        max-width: 80%;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
        &__title {
            width: 100%;
            color: #000;
            font-size: 36px;
            font-family: $font_playfair;
            font-weight: 700;
            margin: 0 0 15px 0;
            @include media-breakpoint-down(lg) {
                font-size: 30px;
            }
        }
        &__subtitle {
            width: 100%;
            color: $color_second;
            font-size: 18px;
            font-family: $font_playfair;
            font-weight: 700;
            margin: 0 0 5px 0;
        }
        &__text {
            width: 100%;
            font-size: 16px;
            font-weight: 300;
            font-family: $font_opensans;
            margin: 0 0 15px 0;
        }
        &__img {
            img {
                margin: 0;
            }
        }
        &__button {
            margin-top: 10px;
        }
        &--left {
            width: 50%;
            border-right: 1px solid #a2a2a2;
            display: flex;
            flex-wrap: wrap;
            text-align: right;
            padding-right: 15px;
            @include media-breakpoint-down(sm) {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid #a2a2a2;
                padding-bottom: 25px;
                margin-bottom: 25px;
            }
        }
        &--right {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            text-align: left;
            padding-left: 15px;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}
.faq {
    .story-item {
        margin-bottom: 30px;
    }
    .story-item__title {
        font-size: 22px;
    }
    .story-item__desc {
        p {
            margin: 20px 0;
        }
        a {
            font-weight: 600;
            color: #c99d3a;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
